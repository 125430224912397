@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  h2 {
    @apply font-krona text-mob/h2 leading-mob/h2 text-left;
  }

  @screen lg {
    h2 {
      @apply text-h2 leading-h2;
    }
  }

  h3 {
    @apply font-montserrat text-mob/h3 leading-mob/h3 text-left;
  }

  @screen lg {
    h3 {
      @apply text-h3 leading-h3;
    }
  }

  h4 {
    @apply font-montserrat text-mob/h4 leading-mob/h4 text-left;
  }

  @screen lg {
    h4 {
      @apply text-h4 leading-h4;
    }
  }

  .text-1 {
    @apply font-montserrat text-mob/text_1 leading-mob/text_1 text-left;
  }

  @screen lg {
    .text-1 {
      @apply text-text_1 leading-text_1;
    }
  }
}

body {
  background: #dfdfdf;
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
